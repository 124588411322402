* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  font-size: 75%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: special, Arial, sans-serif;
}

body {
  &:before,
  &:after {
    top: 0;
    width: 100%;
    content: '';
    height: 100%;
    position: fixed;
    z-index: z(bottom);
  }
  &:before {
    background-image: url('/assets/img/logos/B_logo.svg');
    background-position: top center;
    background-size: 200% auto;
    opacity: 0.1;
  }
  &:after {
    background-color: $Bwhite;
    opacity: 0.5;
  }
}

.max-width-page {
  max-width: map_get($breakpoints, 'desktop-wide');
  margin: auto;
  min-width: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.max-width-box {
  @extend .max-width-page;
  overflow: visible;
}

/*--------------------------------------LINKS -----------_*/

a,
%Blink,
.link {
  color: $Bgrey5;
  text-decoration: underline;
  font-family: basicregular, Arial, sans-serif;
  cursor: pointer;
  font-size: 1.6rem;

  &:hover {
    color: $Bsecondary-dark;
    text-decoration: none;
  }
}

/*------------------------------------ MODALS & DIALOGS --------*/

.Btooltip {
  z-index: z(ceil);
  position: absolute;
}

.Btooltip_back,
.Btooltip_back:before {
  @include cover(fixed);
}

.Btooltip_back {
  background: rgba($Bprimary-dark, 0.93);
}

.Btooltip_back:before {
  content: ' ';
  opacity: 0.8;
}

.Btooltip_content {
  position: absolute;
  background: $Bwhite;
  padding: 20px;
  border-radius: 10px;

  .ko {
    top: -30px;
    right: -30px;
  }
}

.appear {
  opacity: 0;
  animation: appears 0.2s linear forwards;
}

.dialog {
  position: fixed;
  z-index: z(level5);
  background: white;
  width: 45%;
  height: 100%;
  padding: 65px 0 90px 0;
  top: 0;
  font-size: 1.6rem;
  overflow: hidden;
  max-width: 810px;

  &.show + * {
    position: fixed;
    width: 100%;
  }

  .dialog__header {
    background: $Bprimary-light;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;

    &__title {
      font-family: basicbold, Arial, sans-serif;
      font-weight: lighter;
      margin-right: 20px;
      color: $Bwhite;
      padding: 5px 20px;
      font-size: 2rem;
    }
  }

  .dialog__content {
    width: 200%;
    overflow-y: scroll;
    position: relative;
    height: 100%;
    border: solid $Bgrey2;
    border-width: 0 0 1px 0;
    padding: 0 0 50px;
    background: $Bwhite;

    > * {
      display: block;
      max-width: calc(50% + 10px);
    }
  }

  .ko {
    color: $Bwhite;
    font-size: 2rem;
  }
}

.leftdialog {
  @extend .dialog;
  transition:
    left 0.5s,
    opacity 0.5s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  left: -46%;
  opacity: 0;

  &.show {
    left: 0;
    opacity: 1;
    min-width: 600px;
  }

  &.hide {
    opacity: 0;
    left: -46%;
  }
}

.backcover {
  @extend .Btooltip_back;
  z-index: z(level5);
}

.stopper-modal {
  position: fixed;
  width: calc(100% + 30px);
  height: 100%;
  background: rgba($Bprimary-dark, 0.93);
  top: 0;
  left: 0;
  z-index: z(top);
  padding: 0 !important;

  ~ * {
    position: fixed;
  }

  &__scroll {
    position: relative;
    //width:calc(100% + 30px);
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    padding: 0 0 60px 0;
    display: flex;
    justify-content: center;
    @include mq('tablet', 'max') {
      width: calc(100% - 30px);
    }

    &.expand-modal {
      width: 100%;
      padding-right: 15px;

      .ko {
        @include mq('tablet', 'max') {
          left: calc(98% - 5px);
        }
      }

      &__container {
        width: 85%;
      }
    }

    &__container {
      color: $Bprimary;
      text-align: center;
      width: 86%;
      margin: auto;
      max-width: 960px;
      padding: 0 0 5%;
      z-index: z(level1);
      position: absolute;
      top: 60px;

      &__title.h2withbold {
        font-size: 4rem;
        color: $Bwhite;
      }

      &__title.warning {
        font-size: 4rem;
        color: $Borange-light;
      }

      &__content {
        font-size: 1.5rem;
        line-height: 1.2;
        text-align: left;
        margin: 40px 0;
        color: $Bwhite;
        position: relative;
        overflow: hidden;
        transition: min-height 0.2s linear;
        word-break: break-word;

        &.reduced {
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;

          > div {
            border-bottom: 1px solid $Bgrey7;
            padding: 10px 0;
          }
        }

        .Berror & {
          text-align: center;
          color: $Borange_light;
          font-size: 1.7rem;
        }

        &.justify {
          text-align: justify;
          padding: 0 10%;
        }

        br {
          line-height: 30px;
        }

        &__br br {
          line-height: 1;
        }

        &.white_modal {
          background: $Bwhite;
          color: $Bblack;
          padding: 5%;
          border-radius: 0 25px 0 25px;
          max-width: 100%;
        }

        .cssload-loader__container {
          transition: height 0.5s linear;
          min-height: 340px;
        }
      }

      &__button {
        @extend .buttonbluelr;
      }
    }

    .ko {
      @extend .__ko;
      z-index: z(bottom);
    }
  }
}

.__ko {
  position: fixed;
  color: $Bgrey3;
  width: 100px;
  height: 100px;
  top: 0;
  left: calc(50% + 520px);
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq('desktop-wide', 'max') {
    left: 94%;
    width: auto;
    height: 40px;
  }
  @include mq('tablet', 'max') {
    left: calc(98% - 30px);
  }

  &:hover {
    color: $Bwhite;
    transform: none;

    &:before {
      transform: scale(1.1);
      color: $Bsecondary-light;
    }
  }

  &:before {
    @include mq('tablet-small', 'max') {
      font-size: 1.55rem;
    }
  }
}

/*--------------------------------------ICONS -----------_*/

.Bplus {
  @include circle(20px);
  color: $Bprimary-lighter;
  display: inline-flex;
  margin: 0 5px;
  cursor: pointer;
  font-size: 1.8rem;
  justify-content: center;
  align-items: center;
  background: $Btransparent;
  border: 0;
  @extend .plus;

  &:hover {
    color: $Bwhite;
    background: $Bprimary;
  }

  &.mini {
    @include circle(18px);
    font-size: 1.7rem;
  }

  &.special {
    min-width: 40px;
    min-height: 40px;
    font-size: 2.3rem;
    transition: background 0.2s linear;
    background: $Btransparent;
    color: $Bprimary-light;
    border: 1px solid $Bprimary-light;

    &:before {
      opacity: 0.5;
      transition: opacity 0.2s linear;
    }

    &:hover {
      color: $Bprimary;
      border-color: $Bprimary;

      &:before {
        opacity: 1;
      }
    }

    &.reverse {
      background: rgba($Bprimary-light, 0.5);
      color: $Bwhite;

      &:hover {
        background: $Bprimary-light;
        color: $Bprimary;
      }
    }
  }
}

.Bless {
  @extend .Bplus;
  @extend .less;

  &:before {
    letter-spacing: 0;
    font-size: 1.5rem;
    color: $Borange;
  }
}

.ko {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $Bgrey5;
  font-size: 2.5rem;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    color: $Bwhite;
    transform: scale(1.1);
  }
}

#navutils {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: z(top);

  i {
    @include circle(40px);
    transition: opacity 0.2s linear;
    opacity: 0.6;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    background: $Bprimary;
    margin: 5px 0 0;

    &:before {
      opacity: 0.4;
      color: $Bwhite;
    }

    &.hcontrast {
      opacity: 0.8;

      &:before {
        opacity: 0.7;
      }
    }

    &:hover {
      opacity: 1;

      &:before {
        opacity: 1;
      }
    }
  }

  #chaticon {
    background: $Bwhite;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    margin-left: 3px;
    margin-bottom: 15px;

    img {
      min-width: 40px;
      max-width: 40px;
      z-index: 1;
    }

    .unseen {
      position: absolute;
      left: 36px;
      top: 0;
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
    }
  }

  .end {
    transform: rotate(-90deg);
  }

  .ayuda {
    font-size: 4.2rem;
    background: $Btransparent;

    &:before {
      color: $Bprimary;
    }
  }
}

%roundicon {
  width: 50px;
  height: 50px;
  margin: 5px;
  color: $Btransparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  font-size: 0;

  &:before {
    font-size: 3rem;
    color: $Bwhite;
    position: absolute;
  }
}

.menuicons {
  color: $Bgrey5;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  max-width: 75px;
  align-items: center;
  text-align: center;
  line-height: 1;

  &:hover,
  &:focus,
  &.active {
    color: $Bprimary-lighter;
    i.m_icon:before {
      color: $Bwhite;
    }
    i.cancelar:before {
      color: $Berror-dark;
    }
  }

  i {
    @extend %roundicon;
  }

  i.cancelar {
    color: $Berror-light;
    &:before {
      color: $Berror-light;
    }
    &:hover,
    &:focus,
    &.active {
      &:before {
        color: $Berror-dark;
      }
    }
  }

  &.disabled {
    color: $Bgrey5;

    &:hover {
      color: $Bgrey5;
    }

    i.m_icon,
    i.m_icon:hover,
    i.m_icon:focus,
    i.m_icon.active {
      cursor: default;
      opacity: 0.7;
      border: 0;

      &:before {
        transform: scale(1);
        color: $Bgrey5;
      }
    }
  }
}

i.m_icon {
  cursor: pointer;
  transition: background 0.2s linear;
  height: 35px;

  &:before {
    color: $Bprimary-light;
    transition: color 0.2s linear;
  }

  &:hover,
  &:focus,
  &.active {
    color: $Bwhite;
    &:before {
      color: $Bwhite;
      transform: scale(1.1);
    }
  }

  &.active.selected {
    color: $Bwhite;
  }
}

.menuinnericons {
  @extend .menuicons;
  position: absolute;
  top: -35px;
  right: -35px;
  font-size: 1.3rem;

  &:hover,
  &:focus,
  &.active {
    color: $Bblack;
  }

  i {
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}

.soloida,
.idavuelta {
  @extend %roundicon;
  position: absolute;
  border: 2px solid $Bwhite;
}

span.soloida {
  transition: margin 0.1s linear;
  margin: 1rem 0 0 7.5rem;
}

.-icon-hide {
  color: $Bwhite;

  .menosopciones {
    display: none;

    th:hover & {
      position: absolute;
      right: -20px;
    }
  }
}

.menosopciones.arrow-rotate {
  transform: rotate(180deg);
  transform-origin: 50% 47%;
}

.msgbullet {
  background: $Bfourth;
  @include circle(18px);
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: $Bblack;
  animation: pulsebullet 1s alternate infinite;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------------------------------BOX -----------_*/

%Bstfigure {
  img {
    width: 100%;
  }
}

%Bborder {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 30px solid transparent;
}

%removeBdr {
  position: relative;
  bottom: unset;
  left: unset;
  width: unset;
  height: unset;
  border: 0;
}

.Bborder-y {
  @extend %Bborder;
  border-left: 30px solid $Bfourth;
  border-bottom: 30px solid $Bfourth;
}

.Bborder-blr {
  //@extend %Bborder;
  //border-left: 50px solid $Bprimary;
  //border-bottom: 50px solid $Bprimary;
}

.Bborder-bl {
  //@extend %Bborder;
  //border-left: 50px solid $Bthird;
  //border-bottom: 50px solid $Bthird;
}

.Bborder-b {
  @extend %Bborder;
  border-left: 30px solid $Bsecondary;
  border-bottom: 30px solid $Bsecondary;
}

.Bborder-bd {
  //@extend %Bborder;
  //border-left: 50px solid $Bprimary-dark;
  //border-bottom: 50px solid $Bprimary-dark;
}

.Bborder-gr {
  //@extend %Bborder;
  //border-left: 50px solid $Bgrey2;
  //border-bottom: 50px solid $Bgrey2;
}

.Bbacky {
  background-color: $Bsecondary;
}

.Bbackblr,
.Simple {
  background-color: $Bsecondary;
}

.Bbackbl,
.Calendario {
  background-color: rgba($Bsecondary-lighter, 0.4);
}

.Bbackb,
.Multidestino {
  background-color: $Bsecondary-dark;
}

.Bbackbd {
  background-color: $Bprimary-dark;
}

.returnlink {
  position: absolute;
  top: 30px;
  left: 30px;
  text-transform: uppercase;
  @extend %Blink;
}

.brand_header {
  text-align: center;
  background-color: $Btransparent;

  &.Multidestino {
    background: $Bsecondary-dark;
    .h1withbold {
      color: $Bsecondary-lighter;
    }
  }

  &.Calendario {
    background-color: rgba($Bsecondary-light, 0.2);
  }

  .h1withbold {
    margin: 0;
    font-size: 4.5rem;
    padding: 15px 0;
    color: $Bsecondary-dark;
    @include mq('tablet', 'max') {
      font-size: 2.5rem;
    }
  }
}

.orange-pill {
  background: $Borange-light;
  padding: 5px 18px;
  border-radius: 10px;
  display: inline-block;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: $Bblack;
  font-size: 1.1em;
}

/*--------------------------------------TEXT -----------_*/

.logotext {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  padding: 0 10px;
  color: $Bprimary-dark;

  span,
  i {
    background: $Bsecondary;
    padding: 1px 10px;
    border: 2px solid $Bprimary-dark;
  }

  span:nth-of-type(1) {
    margin: 10px 0 10px 10px;
    border-width: 2px 0 2px 2px;
  }

  span:nth-of-type(2) {
    margin: 10px 10px 10px 0;
    border-width: 2px 2px 2px 0;
  }

  i {
    padding: 9px 10px 5px;
    top: 2px;
    position: relative;

    &:before {
      transform: rotate(-45deg);
      display: inline-block;
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}

.h1withbold {
  font-size: 5rem;
  font-family: basiclight, Arial, sans-serif;
  font-weight: lighter;
  margin: 0 0 10px 0;
  color: $Bprimary;

  strong {
    font-family: basicbold, Arial, sans-serif;
    font-weight: lighter;
  }
}

.h2withbold {
  @extend .h1withbold;
  font-size: 3.5rem;
  color: $Bblack;
}

.h3withbold {
  @extend .h1withbold;
  font-family: basicregular, Arial, sans-serif;
  font-size: 2.5rem;
  color: $Bprimary;
}

.h4withbold {
  @extend .h1withbold;
  font-size: 1.8rem;
  font-family: basicregular, Arial, sans-serif;
  color: $Bprimary-lighter;

  .Berror {
    position: relative;
    bottom: 0;
    display: block;
    padding: 5px 0;
    font-size: 0.8em;
  }
}

.Bwarning {
  color: $Borange;
}

.Berror {
  color: $Berror;
  padding: 20px;
  display: inline-block;
}

%Btext_light {
  font-family: basiclight, Arial, sans-serif;
  font-size: 1.6rem;
  max-width: 700px;
}

%Btext_reg {
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.6rem;
  max-width: 700px;
}

.tstop {
  font-family: basicregular, Arial, sans-serif;
  color: $Bviolet-dark;
  padding: 2px 0;
  line-height: 1;
  font-size: 0.9em;
}

.leftdialog {
  padding-bottom: 20px;
  .extendedinfo__container__div {
    position: absolute;
    top: 180px;
    bottom: 25px;
    display: block;

    .extendedinfo__container__code {
      max-height: 100%;
      max-width: 760px;
    }
  }
}

sbmn-flights-offers .banner_home_footer {
  display: none;
}

section.fresults_filters,
#main_header .mobile_menu_container__sidenav,
.mybookings__filters,
.mymessages__filters,
.mtausers__filters,
.mtabalance__filters {
  box-shadow: 1px 0 5px 0 rgba(128, 138, 153, 62);
  border: 0;
  background: #fff !important;
}

sbmn-flights-filters section {
  min-width: auto;
}

sbmn-flights-filters section .clock + span {
  display: block;
  margin-top: 2px;
  margin-left: 22px;
  text-align: left;
}

sbmn-flights-results {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'header header'
    'sidebar main';
}

sbmn-flights-results > header {
  grid-area: header;
}

sbmn-flights-results > section.fresults_filters {
  grid-area: sidebar;
  position: relative;
  width: 280px;
  min-width: auto;
  box-shadow: none;
  background-color: transparent !important;
}

sbmn-flights-results header.sticky.hhide,
sbmn-flights-calendar header.sticky.hhide {
  background-color: var(--ct-color_aux);
}

sbmn-flights-results > section#fresults {
  grid-area: main;
}

sbmn-flights-results > #fcalendar {
  grid-area: main;
}

.voucher__sendBondForm {
  .mat-form-field-label {
    color: $Bprimary-light !important;
  }

  .mat-form-field-underline {
    background-color: $Bprimary-light !important;
  }

  mat-form-field {
    color: $Bprimary-light !important;

    mat-hint {
      color: $Bprimary-light !important;
    }
  }
}
